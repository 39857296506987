<template>
  <div class="px-5">
    <div class="mb-5">
      <div class="font-weight-bold text-h5 mr-5">Chuyển hàng</div>
    </div>
    <v-row>
      <v-col class="py-0" cols="8">
        <products-detail></products-detail>
      </v-col>

      <v-col class="scrollable overflow-auto py-0" cols="4">
        <general></general>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import General from "./components/General";
import ProductsDetail from "./components/ProductDetail/index";

export default {
  components: {
    General,
    ProductsDetail
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    }
  }
};
</script>

<style lang="scss" scoped>
.scrollable {
  max-height: calc(100vh - 64px - 53px - 48px - 24px);
  overflow-y: scroll;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 48px - 24px);
  }
}
</style>
