<template>
  <div class="">
    <top-form></top-form>
    <v-card class="card-wrap mt-5" flat>
      <v-data-table
        class="table-scroll tp-table__row-pointer datatable px-3 py-2"
        calculate-widths
        disable-pagination
        no-data-text="Không có dữ liệu"
        no-results-text="Không tìm thấy kết quả phù hợp"
        :headers="headers"
        hide-default-footer
        :loading="false"
        loading-text="Đang tải dữ liệu"
        :items="goodsDelivery.options"
        item-key="id"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ indexOfOptionIds(item.id) + 1 }}
        </template>

        <template v-slot:[`item.product_name`]="{ item }">
          <div class="my-1">
            <div class="font-weight-medium">
              {{ item.product_name }}
            </div>
            <div
              class="text-subtitle-2 font-weight-light grey--text text--darken-2"
            >
              <span class="font-weight-bold">{{ item.SKU }} - </span>
              <span class="item-subtitle mr-1" v-html="item.name"></span>
            </div>
          </div>
        </template>

        <template v-slot:[`item.serials`]="{ item }">
          <div style="min-width: 200px">
            <v-autocomplete
              v-model="item.serials"
              class="tp-filter-autocomplete text-body-1 my-2"
              dense
              deletable-chips
              :items="item.allSerials"
              item-text="serial_number"
              item-value="id"
              hide-details
              hide-selected
              multiple
              no-data-text="Không có dữ liệu"
              outlined
              placeholder="Chọn serials"
              single-line
              small-chips
              return-object
              @focus="getSerialsByOptionId(item)"
            >
            </v-autocomplete>
          </div>
        </template>

        <template v-slot:[`item.quantity`]="{ item }">
          {{ item.serials.length }}
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | formatCurrency }}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          {{ (item.price * item.serials.length) | formatCurrency }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :color="hover ? 'grey darken-1' : 'grey lighten-1'"
              icon
              small
              @click.stop="removeProductOption(item)"
            >
              <v-icon size="18px">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-hover>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import TopForm from "./components/TopForm";

export default {
  components: {
    TopForm
  },
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "Serials",
          align: "start",
          sortable: false,
          value: "serials"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Đơn giá",
          align: "start",
          sortable: false,
          value: "price"
        },
        {
          text: "Tổng giá trị",
          align: "start",
          sortable: false,
          value: "total"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    filterProductOptionSerials() {
      return this.productOptionSerials.filter(
        serial => serial.status !== -1 && serial.exchange_status === 0
      );
    },
    filterSearchProductOptions() {
      const arr = this.goodsDelivery.options.map(item => item.id);

      return this.searchProductOptions.filter(item => !arr.includes(item.id));
    },
    goodsDelivery() {
      return this.$store.getters["GOODS_DELIVERY/goodsDelivery"];
    },
    optionIds() {
      return this.goodsDelivery.options.map(item => item.id);
    },
    productOptionSerials() {
      return this.$store.getters["SERIAL/productOptionSerials"];
    }
  },
  watch: {
    "goodsDelivery.branch_xuat_id"() {
      this.goodsDelivery.options.forEach(option => {
        option.serials = [];
        option.allSerials = [];
      });
    }
  },
  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
  },
  methods: {
    indexOfOptionIds(value) {
      return this.optionIds.indexOf(value);
    },

    async getSerialsByOptionId(option) {
      if (option.allSerials.length === 0) {
        let res = [];

        await this.$store.dispatch("SERIAL/getSerialsByOptionId", option.id);

        this.branches.forEach(branch => {
          // Filter serial by branch id
          let arr = this.filterProductOptionSerials.filter(
            item => item.branch_id === branch.id
          );

          arr = arr.map(value => {
            return {
              ...value,
              disabled:
                this.goodsDelivery.branch_xuat_id &&
                this.goodsDelivery.branch_xuat_id !== branch.id
            };
          });

          res = res.concat(arr);
        });

        const sortedDataByDisabled = res.sort((a, b) => {
          if (a.disabled === b.disabled) {
            return 0;
          }
          return a.disabled ? 1 : -1;
        });

        option.allSerials = this.groupByBranch(sortedDataByDisabled);
      }
    },

    groupByBranch(data) {
      const groupedData = data.reduce((result, item) => {
        if (!result[item.branch_name]) {
          result[item.branch_name] = [];
        }
        result[item.branch_name].push(item);
        return result;
      }, {});

      const groupedArray = [];
      for (const key in groupedData) {
        groupedArray.push({ header: `${key} (${groupedData[key].length})` });
        groupedArray.push(...groupedData[key]);
      }
      return groupedArray;
    },

    removeProductOption(item) {
      this.goodsDelivery.options.splice(this.indexOfOptionIds(item.id), 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 48px - 60px - 24px);
  @media only screen and (max-width: 960px) {
    min-height: calc(100vh - 56px - 53px - 48px - 60px - 24px);
  }
}
.table-scroll {
  max-height: calc(100vh - 64px - 53px - 48px - 60px - 16px - 24px);
  overflow-y: scroll;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 48px - 60px - 16px - 24px);
  }
}
.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
</style>
