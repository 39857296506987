<template>
  <v-card class="card-wrap px-5 py-4" flat>
    <div>
      <div class="mb-1">
        <span class="font-weight-bold">Nhân viên: </span>
        <span>{{ user.name }}</span>
      </div>
      <div class="mb-1">
        <span class="font-weight-bold">Ngày tạo: </span>
        <span>{{ getToday() }}</span>
      </div>
    </div>

    <v-divider class="my-4"></v-divider>
    <v-checkbox
      v-model="goodsDelivery.self_shipping"
      label="ThinkPro tự vận chuyển"
      class="mt-0 mb-0"
      hide-details
      dense
      :disabled="goodsDelivery.id"
      @change="goodsDelivery.shipping_supplier_id = ''"
    ></v-checkbox>
    <!-- Start: Preparing employer -->
    <div
      class="d-flex flex-row align-end justify-space-between mt-4"
      v-if="goodsDelivery.self_shipping"
    >
      <div class="col-6 font-weight-bold pa-0">
        Người vận chuyển:
      </div>
      <div class="col-6 pa-0">
        <v-autocomplete
          v-model="goodsDelivery.prepared_by_id"
          class="text-body-1 mt-0"
          clearable
          dense
          flat
          hide-details
          :items="employeesSameBranchWithUser"
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          placeholder="Chọn nhân viên"
        ></v-autocomplete>
      </div>
    </div>
    <!-- End: Preparing employer -->

    <!-- Start: Km -->
    <div
      class="d-flex flex-row align-center justify-space-between mt-4"
      v-if="goodsDelivery.self_shipping"
    >
      <div class="font-weight-bold">
        Số km
      </div>
      <div class="col-6 text-right pa-0">
        <v-text-field
          v-model="goodsDelivery.distance"
          class="text-body-1"
          clearable
          dense
          hide-details="auto"
          outlined
          placeholder="Số km"
        ></v-text-field>
      </div>
    </div>
    <!-- End: Km -->
    <div v-if="!goodsDelivery.self_shipping">
      <div class="mt-4 d-flex flex-row align-center justify-space-between">
        <div class="font-weight-bold mr-4">Đơn vị chuyển</div>
        <v-autocomplete
          v-model="goodsDelivery.shipping_supplier_id"
          class="text-body-1 mt-0"
          clearable
          dense
          flat
          hide-details
          :items="suppliersTransport"
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          placeholder="Chọn đơn vị vận chuyển"
          style="max-width: 240px"
          :full-width="true"
        ></v-autocomplete>
      </div>
      <div
        class="d-flex flex-row align-end justify-space-between mt-4"
        v-if="goodsDelivery.shipping_supplier_id"
      >
        <div class="col-6 font-weight-bold pa-0">
          Nhân viên đóng gói, gửi hàng:
        </div>
        <div class="col-6 pa-0 d-flex align-center justify-end">
          <v-autocomplete
            v-model="goodsDelivery.user_sending"
            class="text-body-1 mt-0"
            dense
            flat
            hide-details
            :items="employeesSameBranchWithUser"
            style="max-width: 240px"
            item-text="name"
            item-value="id"
            no-data-text="Không có dữ liệu"
            placeholder="Chọn nhân viên"
          ></v-autocomplete>
        </div>
      </div>
      <div class="d-flex flex-row align-center justify-space-between mt-4">
        <div class="font-weight-bold mr-4">Phí vận chuyển:</div>
        <tp-input-price
          v-model="goodsDelivery.fee"
          custom-class="text-body-1 price--text-right pt-0 mt-0 mb-2"
          custom-style="max-width: 240px"
          hide-details
          placeholder="Nhập giá trị"
        ></tp-input-price>
      </div>
    </div>

    <v-divider class="my-4"></v-divider>

    <div class="d-flex flex-row align-center justify-space-between">
      <div class="font-weight-bold">Tổng giá trị chuyển</div>
      <div class="text-h6">
        {{ goodsDeliveryTotal | formatCurrency }}
      </div>
    </div>

    <div class="mt-4">
      <div class="font-weight-bold mb-2">Ghi chú chuyển</div>
      <v-textarea
        v-model="goodsDelivery.note"
        class="text-body-1"
        background-color="white"
        dense
        filled
        outlined
        placeholder="Nhập ghi chú"
        single-line
        required
      ></v-textarea>
    </div>

    <div class="" v-if="goodsDelivery.id">
      <div class="font-weight-bold mb-2">Ghi chú nhận</div>
      <v-textarea
        v-model="goodsDelivery.note"
        class="text-body-1"
        background-color="white"
        dense
        filled
        outlined
        placeholder="Nhập ghi chú"
        single-line
        required
      ></v-textarea>
    </div>

    <v-card-actions class="justify-space-between pa-0">
      <v-btn
        v-if="goodsDelivery.id"
        class="rounded-lg"
        color="grey lighten-3"
        depressed
        >Hủy</v-btn
      >
      <v-btn
        class="rounded-lg"
        color="primary"
        depressed
        :loading="
          goodsDeliveryStatusRequest.value === 'loading-createGoodsDelivery'
        "
        @click="
          goodsDeliveryStatusRequest.value === 'loading-createGoodsDelivery'
            ? null
            : createGoodsDelivery()
        "
        >Chuyển hàng</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  created() {
    if (this.suppliersTransport && this.suppliersTransport.length === 0)
      this.$store.dispatch("SUPPLIER/getSuppliers", {
        cur_page: 1,
        filter: {
          fromBalance: null,
          fromBuy: null,
          fromDate: null,
          fromReturn: null,
          sup_cate_ids: [3],
          toBalance: null,
          toBuy: null,
          toDate: null,
          toReturn: null
        },
        per_page: 50,
        search: null
      });
    if (this.employees && this.employees.length === 0)
      this.$store.dispatch("EMPLOYEE/getAllEmployees");
  },
  computed: {
    goodsDelivery() {
      return this.$store.getters["GOODS_DELIVERY/goodsDelivery"];
    },
    goodsDeliveryStatusRequest() {
      return this.$store.getters["GOODS_DELIVERY/statusRequest"];
    },
    goodsDeliveryTotal() {
      let total = 0;

      this.goodsDelivery.options.forEach(item => {
        total += item.serials.length * item.price;
      });

      return total;
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    suppliersTransport() {
      return this.$store.getters["SUPPLIER/suppliers"];
    },
    employeesSameBranchWithUser() {
      let employeesSameBranchWithUsers = [];
      if (
        this.goodsDelivery &&
        this.goodsDelivery.branch_xuat_id &&
        this.employees
      ) {
        this.employees.map(item => {
          if (item.branch_id === this.goodsDelivery.branch_xuat_id)
            employeesSameBranchWithUsers.unshift(item);
        });
        this.employees.map(item => {
          if (item.branch_id !== this.goodsDelivery.branch_xuat_id)
            employeesSameBranchWithUsers.push(item);
        });
        return employeesSameBranchWithUsers;
      } else return this.employees;
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    async createGoodsDelivery() {
      const obj = { ...this.goodsDelivery };
      if (
        (obj.self_shipping && !obj.prepared_by_id) ||
        (obj.self_shipping && !obj.distance)
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thiếu trường nhân viên đóng gói vận chuyển hoặc số km"
          }
        });
      } else if (!obj.self_shipping && !obj.shipping_supplier_id) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thiếu trường đơn vị vận chuyển"
          }
        });
      } else {
        obj.self_shipping = !!obj.self_shipping;
        let serials = [];

        await obj.options.forEach(option => {
          const arr = option.serials.map(item => item.id);

          serials = serials.concat(arr);
        });

        obj.serials = serials;
        // Request
        await this.$store.dispatch("GOODS_DELIVERY/createGoodsDelivery", obj);
      }
      // Alert
      if (
        this.goodsDeliveryStatusRequest.value === "success-createGoodsDelivery"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm phiếu chuyển thành công"
          }
        });
        // Go to listing page
        await this.$router.push({
          name: "transaction_goods-deliveries",
          query: {
            limit: 50,
            page: 1
          }
        });
      }
    },

    getToday() {
      const date = new Date(),
        hour = date
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = date
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        dd = date
          .getDate()
          .toString()
          .padStart(2, "0"),
        mm = (date.getMonth() + 1).toString().padStart(2, "0"),
        yyyy = date.getFullYear();

      return `${hour}:${mins} ${dd}/${mm}/${yyyy}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 48px - 24px);
  @media only screen and (max-width: 960px) {
    min-height: calc(100vh - 56px - 53px - 48px - 24px);
  }
}
</style>
