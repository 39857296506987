var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('top-form'),_c('v-card',{staticClass:"card-wrap mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":false,"loading-text":"Đang tải dữ liệu","items":_vm.goodsDelivery.options,"item-key":"id"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.indexOfOptionIds(item.id) + 1)+" ")]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.product_name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-light grey--text text--darken-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.SKU)+" - ")]),_c('span',{staticClass:"item-subtitle mr-1",domProps:{"innerHTML":_vm._s(item.name)}})])])]}},{key:"item.serials",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"200px"}},[_c('v-autocomplete',{staticClass:"tp-filter-autocomplete text-body-1 my-2",attrs:{"dense":"","deletable-chips":"","items":item.allSerials,"item-text":"serial_number","item-value":"id","hide-details":"","hide-selected":"","multiple":"","no-data-text":"Không có dữ liệu","outlined":"","placeholder":"Chọn serials","single-line":"","small-chips":"","return-object":""},on:{"focus":function($event){return _vm.getSerialsByOptionId(item)}},model:{value:(item.serials),callback:function ($$v) {_vm.$set(item, "serials", $$v)},expression:"item.serials"}})],1)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serials.length)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.price))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")((item.price * item.serials.length)))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'grey darken-1' : 'grey lighten-1',"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeProductOption(item)}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }